const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://vym.dvgeo.app' : 'http://192.168.1.106:3011',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://vym.dvgeo.app' : 'http://192.168.1.106:3011',
    api: 'api/',
    apiSocket: 'vym::1.2',
    nameDir: 'vym',
    package: 'app.dvgeo.vym.web',
    version: '1.0.7',
    googleAPIKey: 'AIzaSyBFbE8xV71JeUS0xfOa9wJaQY4XwaLogD4',
    appName: 'VYM',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#CBAB62',
    colorDark: '#CB9243',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.devjos.vym',
    playStoreDriverId: 'com.devjos.vym.driver',
    appStorePassengerId: '1560682945',
    appStoreDriverId: '1563578210',
    email: "apserviceexpresvm@gmail.com",
};
export default config;
